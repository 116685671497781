/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import AboutModal from './AboutModal';
import PartnerService from '../services/partner.service';
import ReleaseNotesService from '../services/releasenotes.service';
import { ModalView, Event } from './GoogleAnalytics';

export class Landing extends Component {
    static displayName = Landing.name;

    constructor(props) {
        super(props);

        this.openAboutModal = this.openAboutModal.bind(this);
        this.showAboutModal = this.showAboutModal.bind(this);
        this.onVersionSelect = this.onVersionSelect.bind(this);
        this.onSubmitNotes = this.onSubmitNotes.bind(this);

        this.state = {
            loading: false,
            showAbout: false,
            releaseNotes: [],
            selectedVersion: {},
            selectedVersionIndex: 0
        }
    }

    openAboutModal() {
        Event("Version Clicked", "User clicked version on landing page to view release notes", "Version Clicked");
        this.setState({ loading: true }, async () => {
            await ReleaseNotesService.getreleasenotes()
                .then(response => {
                    this.setState({ releaseNotes: response, showAbout: true }, () => {
                        if (this.state.releaseNotes !== null && this.state.releaseNotes !== undefined && this.state.releaseNotes.length) {
                            var index = this.state.releaseNotes.length - 1;
                            var selectedVersion = this.state.releaseNotes[index];
                            this.setState({ selectedVersion: selectedVersion, selectedVersionIndex: index, loading: false })
                        }
                        else {
                            this.setState({ releaseNotes: [], loading: false });
                        }
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false });
                })
        })
    }

    showAboutModal() {
        this.setState({ showAbout: !this.state.showAbout }, () => {
            if (this.state.showAbout)
                ModalView('aboutRelease');
        });
    }

    onVersionSelect(e) {
        let index = e.target.value;

        this.setState({ selectedVersion: this.state.releaseNotes[index], selectedVersionIndex: index });
    }

    onSubmitNotes(version, notes) {
        Event("Release Notes Submitted", "User submitted release notes", "Release Notes Submitted");
        this.setState({ loading: true }, async () => {
            var newNotes = { version: version, notes: notes };
            await ReleaseNotesService.addreleasenotes(newNotes)
                .then(response => {
                    let newNote = response;
                    console.log("newNote:");
                    console.log(newNote);
                    let releaseNotes = this.state.releaseNotes;
                    releaseNotes.push(newNote);
                    this.setState({ releaseNotes: releaseNotes }, () => {
                        if (this.state.releaseNotes !== null && this.state.releaseNotes !== undefined && this.state.releaseNotes.length) {
                            var index = this.state.releaseNotes.length - 1;
                            var selectedVersion = this.state.releaseNotes[index];
                            this.setState({ selectedVersion: selectedVersion, selectedVersionIndex: index, loading: false })
                        }
                        else {
                            this.setState({ releaseNotes: [], loading: false });
                        }
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        });
    }

    render() {
        return (
            <div style={{ textAlign: 'center', zIndex: '-1' }} className='landingPage'>                
                <h1>Pro-IQ&reg; LifeWhere</h1>
                <p>Portal</p>
                <div>
                    <button onClick={this.openAboutModal} style={{ background: 'none', border: 'none', color: '#0033a0' }}>
                        Version 2.44a
                    </button>
                </div>
                <img src={require('../img/flow-2.png')} alt='ApplicationLogo' />

                <AboutModal show={this.state.showAbout} onClick={this.showAboutModal} releaseNotes={this.state.releaseNotes} loading={this.state.loading} selectedVersion={this.state.selectedVersion} selectedVersionIndex={this.state.selectedVersionIndex} onVersionSelect={this.onVersionSelect} onSubmitNotes={this.onSubmitNotes} isPortalAdmin={this.props.roles.includes("PORTAL ADMIN") || this.props.roles.includes("ADMIN")} />
                
            </div>
        );
  }
}

/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import styled from "styled-components";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';

import PartnerService from "../../services/partner.service";
import CustomerService from "../../services/customer.service";

import SelectPartner from "./SelectPartner";
import SelectCustomer from "./SelectCustomer";
import SearchCustomer from "./SearchCustomer";
import SideNav from "./SideNav";

import memoize from "memoize-one";

import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";
import AccountService from "../../services/account.service";

import { jsAsset, jsHouse, jsCurrentAccount } from "../../componentObjects";
import { Event, Timing } from "../GoogleAnalytics";
import withDataContext from "../Contexts/WithDataContext";

class Sidebar extends Component {
    _isMounted = false;
    pastCustomerList = [];

    constructor(props) {
        super(props);
        this.state = {
            isChildMounted: false,
            customerName: null,
            showLogon: false,
            myCurrentPartnerId: "-1",
            myCurrentCustomerId: "-1",
            serviceTier: "0",
            partnerList: [],
            customerList: [],
            homeList: [],
            hvacList: [],
            assetList: [],
            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentAsset: jsAsset,
            reloadSidebar: true,
            startTime: null,
            searchType: "name",
            partnerListChanged: false,
            emailLoaded: false,
            addressLoaded: false,
            hasServiceTitan: false,
            hasBilling: false,
            isActive: false,
            rentalAgreementCustomerValue : -1
        };

        this.onPartnerSelect = this.onPartnerSelect.bind(this);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.onCustomerSearch = this.onCustomerSearch.bind(this);
        this.setDefaultCustomer = this.setDefaultCustomer.bind(this);
        this.setNoCustomers = this.setNoCustomers.bind(this);
        this.loadSidebar = this.loadSidebar.bind(this);


        this.setData = this.setData.bind(this);
        this.setPartnerCallback = this.setPartnerCallback.bind(this);
        this.setCustomerCallback = this.setCustomerCallback.bind(this);
        this.setCustomerList = this.setCustomerList.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadSidebar(this.props.reloadSidebar);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.customerList !== this.props.customerList) {
            this.setState({ customerList: this.props.customerList })
        }
    }

    memSidebar = memoize(this.loadSidebar);

    async loadSidebar(value) {
        if (this.props.reloadSidebar == true) {
            var startTime = performance.now();
            this.setState({ startTime: startTime, searchType: "name" });
            this.props.setLoadingFromChild(true);
            let partnerObj = {
                organization: this.props.loggedInCustomerOrganization,
                isPartnerAdmin: this.props.isPartnerAdmin,
                isActive: true
            };
            await PartnerService.get(partnerObj) //this.props.loggedInCustomerOrganization, this.props.isPartnerAdmin, true)
                .then(async response => {
                    if (response.status === 401) {
                        this.props.onLogOff();
                    }
                    else {
                        if (this._isMounted) {
                            let attrTbl = response.table2;
                            let hasServTierAttr = attrTbl !== null && attrTbl !== undefined ? attrTbl.some(a => a.name == "ServiceTier" && a.organizationName == this.props.loggedInCustomerOrganization) : false;
                            let servTier = "0";
                            if (hasServTierAttr)
                                servTier = attrTbl.find(a => a.name == "ServiceTier" && a.organizationName == this.props.loggedInCustomerOrganization).value;

                            let hasST = attrTbl.some(a => a.name == "Beta-ST" && a.organizationName == this.props.loggedInCustomerOrganization);
                            if (hasST == true)
                                hasST = attrTbl.find(a => a.name == "Beta-ST" && a.organizationName == this.props.loggedInCustomerOrganization).value == "1" ? true : false;

                            let hasBilling = attrTbl.some(a => a.name == "IsBillable" && (a.organizationName == this.props.loggedInCustomerOrganization || this.props.loggedInCustomerOrganization == "LifeWhere" ));
                            let RentalAgreementCustomer = attrTbl.some(a => a.name == "RentalAgreementCustomer" && a.organizationName == this.props.loggedInCustomerOrganization);

                            let RentalAgreementCustomerValue  = -1
                            if (hasBilling && this.props.loggedInCustomerOrganization != "LifeWhere")
                                hasBilling = attrTbl.find(a => a.name == "IsBillable" && a.organizationName == this.props.loggedInCustomerOrganization).value == "1" ? true : false;

                            if (hasBilling && RentalAgreementCustomer)
                                RentalAgreementCustomerValue = attrTbl.find(a => a.name == "RentalAgreementCustomer" && a.organizationName == this.props.loggedInCustomerOrganization)?.value;

                            let isActive = attrTbl.some(a => a.name == "IsActive" && a.organizationName == this.props.loggedInCustomerOrganization);
                            if (isActive)
                                isActive = attrTbl.find(a => a.name == "IsActive" && a.organizationName == this.props.loggedInCustomerOrganization).value == "1" ? true : false;

                            this.setState({ partnerList: response.table, serviceTier: servTier, hasServiceTitan: hasST, hasBilling: hasBilling, isActive: isActive, rentalAgreementCustomerValue: RentalAgreementCustomerValue }, async () => {
                                this.props.setPartnerList(this.state.partnerList);
                                this.props.setServiceTier(this.state.serviceTier);
                                this.props.setServiceTitan(this.state.hasServiceTitan);
                                this.props.setBilling(this.state.hasBilling);
                                this.props.setIsActive(this.state.isActive);
                                this.props.AgreementCustomerValue(this.state.rentalAgreementCustomerValue);
                                this.props.setAttributeList(attrTbl);
                                this.props.context.setSelectedData(attrTbl);
                                if (this.state.partnerList !== undefined && this.state.partnerList !== null) {
                                    // calls setpartnercallback when the setState is complete
                                    this.setState({ isChildMounted: true });
                                    if (this.state.myCurrentPartnerId === "-1") {
                                        let currentPartnerId = this.props.currentPartnerId > 0 ? this.props.currentPartnerId : this.state.partnerList[0].organizationId;
                                        this.setState({
                                            myCurrentPartnerId: currentPartnerId,
                                            myCurrentCustomerId: this.props.currentCustomerId }, ()=>{
                                            this.props.setCurrentPartnerId(this.state.myCurrentPartnerId, this.setPartnerCallback, false);
                                        });

                                    }
                                    else if (this.props.currentPartnerId > 0 && this.state.myCurrentPartnerId != this.props.currentPartnerId) {
                                        this.setState({ myCurrentPartnerId: this.props.currentPartnerId });
                                        await this.setPartnerCallback();
                                    }
                                    else {
                                        await this.setPartnerCallback();
                                    }
                                }
                                else {
                                    // we are likely not logged in.. 
                                    this.props.onLogOff();
                                }
                            });
                        }
                    }
                }).catch(e => {
                    console.log(e);
                    this.props.onLogOff();
                });
        }
    }

    async getDetailedCustomerList(partnerId) {
        await CustomerService.getcustomerlistplus(partnerId, "email", this.props.customerList)
            .then(response => {
                var list = response.data;
                var newList = true;
                if (list.length < this.props.customerList.length) {
                    list = this.props.customerList;
                    newList = false;
                }
                this.setState({ customerList: list, emailLoaded: newList }, async () => {
                    await CustomerService.getcustomerlistplus(partnerId, "address", this.props.customerList)
                        .then(response2 => {
                            list = response.data;
                            newList = true;
                            if (list.length < this.props.customerList.length) {
                                list = this.props.customerList;
                                newList = false;
                            }
                            this.setState({ customerList: list, addressLoaded: newList });
                        })
                })
            }).catch(e => {
                console.log(e)
            });
    }

    async onPartnerSelect(e) {
        Event("Partner Select", "User selected a partner", "Partner Select");
        var startTime = performance.now();

        this.setState({ startTime: startTime, myCurrentPartnerId: e.target.value, emailLoaded: false, addressLoaded: false });

        this.props.setLoadingFromChild(true);
        localStorage.setItem('loading', JSON.stringify(true));

        this.props.setCurrentPartnerId(e.target.value, this.setPartnerCallback, true); 
    }

    async getCustomerList(partnerId, keepLoading = false) {
        await CustomerService.get(partnerId > 0 ? partnerId : this.state.partnerList[0].organizationId).then(customers => {
            this.setState({ customerList: customers, emailLoaded: false, addressLoaded: false, partnerListChanged: true },()=>{
                if (customers.length) {
                    let isNewPartner = this.state.customerList.every(f=>f.organizationId !== this.state.myCurrentCustomerId);
                    let currentCustomerId = isNewPartner ? this.state.customerList[0].organizationId : this.state.myCurrentCustomerId;
                    this.setDefaultCustomer(currentCustomerId);
                    this.props.setCustomerList(this.state.customerList);
                }
                else{
                    this.setNoCustomers();
                    this.setState({ customerList: [] }, () =>{
                        this.props.setCustomerList(this.state.customerList);
                    });
                }
                if (!keepLoading)
                    this.props.setLoadingFromChild(false);
            })
        }).catch(e => {
            console.log(e)
        });
    }
    async setPartnerCallback() {
        this.setState({ myCurrentPartnerId: this.state.myCurrentPartnerId, searchType: "name" }); //props.currentPartnerId

        /* Was commented out to fix random reload previously*/
        await this.getCustomerList(this.props.currentPartnerId > 0 ? this.props.currentPartnerId : this.state.partnerList[0].organizationId);

        /* Was uncommented for initial fix for random reload (Now exists in App.js > getCustomerList */
        /*await CustomerService.get(//this.props.currentPartnerId > 0 ? this.props.currentPartnerId// this.state.myCurrentPartnerId > 0 ? this.state.myCurrentPartnerId : this.state.partnerList[0].organizationId)
            .then(customers => {
                this.setState({ customerList: customers, emailLoaded: false, addressLoaded: false }, async () => {
                    localStorage.setItem('customerList', JSON.stringify(this.state.customerList));
                    localStorage.setItem('emailLoaded', false);
                    localStorage.setItem('addressLoaded', false);
                    if (this.state.customerList.length) {
                        this.setDefaultCustomer(this.props.currentCustomerId > 0 ? this.props.currentCustomerId : this.state.customerList[0].organizationId);
                        //this.setState({ myCurrentCustomerId : this.state.myCurrentCustomerId > 0 ? this.state.myCurrentCustomerId : this.state.customerList[0].organizationId });
                        this.props.setLoadingFromChild(false); 
                        await CustomerService.getcustomerlistplus(this.state.myCurrentPartnerId, "email", this.state.customerList)
                            .then(response => {
                                var list = response.data;
                                var newList = true;
                                if (list.length < this.state.customerList.length) {
                                    list = this.state.customerList;
                                    newList = false;
                                } 
                                this.setState({ customerList: list, emailLoaded: newList }, async () => {
                                    localStorage.setItem('customerList', JSON.stringify(this.state.customerList));
                                    localStorage.setItem('emailLoaded', this.state.emailLoaded);
                                    await CustomerService.getcustomerlistplus(this.state.myCurrentPartnerId, "address", this.state.customerList)
                                        .then(response2 => {
                                            list = response2.data;
                                            newList = true;
                                            if (list.length < this.state.customerList.length) {
                                                list = this.state.customerList;
                                                newList = false;
                                            } 
                                            this.setState({ customerList: list, addressLoaded: newList }, () => {
                                                localStorage.setItem('customerList', JSON.stringify(this.state.customerList));
                                                localStorage.setItem('addressLoaded', this.state.addressLoaded);
                                            });
                                        })
                                });
                            })
                            

                    } else {
                        this.setNoCustomers();
                        this.props.setLoadingFromChild(false); 
                        var elapsedTime = performance.now() - this.state.startTime;
                        Timing("Sidebar Loading", "loading", elapsedTime, "Sidebar Loading");
                        this.setState({ startTime: null });
                    }
                });
        }).catch(e => {
            console.log(e)
        });
        */
    }

    onCustomerSelect(e) {
        Event("Customer Select", "User selected a customer", "Customer Select");
        var startTime = performance.now();
        this.setState({ startTime: startTime });

        this.setDefaultCustomer(e.target.value);
    }

    onCustomerSearch(e) {
        Event("Customer Search", "User searched for a customer", "Customer Search");
        var startTime = performance.now();
        this.setState({ startTime: startTime });

        this.setDefaultCustomer(e.organizationId);
    }

    setDefaultCustomer(id) {
        this.props.setCurrentCustomerId(id, this.setCustomerCallback);

        this.setState({ myCurrentCustomerId: id }, () => {
            localStorage.setItem('currentPartnerId', this.state.myCurrentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.myCurrentCustomerId);
        });
    }

    setCustomerCallback() {
        this.setState({ myCurrentCustomerId: this.props.currentCustomerId });
    }

    setNoCustomers() {
        this.setState({ myCurrentCustomerId: -1, homeList: [], currentHome: jsHouse, hvacList: [], currentHVACSystem: jsHouse, assetList: [], currentAsset: jsAsset, loading: false, emailLoaded: false, addressLoaded: false}, async () => {
            localStorage.setItem('currentCustomerId', -1);
            this.setData(false, this.state.partnerList, this.state.myCurrentPartnerId/*props*/, this.state.myCurrentCustomerId/*props*/, this.state.homeList, this.state.hvacList, this.state.assetList, this.state.serviceTier);
        });
    }

    async setCustomerList(type) {
        //this.props.setLoadingFromChild(true); // todo check!!  
        this.setState({ customerList: this.state.customerList/*props*/ }, async () => {
            await CustomerService.getcustomerlistplus(this.state.myCurrentPartnerId/*props*/, type, this.state.customerList)
                .then(response => {
                    var list = response.data;
                    if (list.length < this.state.customerList) {
                        list = this.state.customerList;
                    }
                    this.setState({ customerList: list }, () => {
                        this.props.setLoadingFromChild(false); 
                        localStorage.setItem('customerList', JSON.stringify(this.state.customerList));
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    async populateCustomerData() {
        await HomeService.get(this.state.currentCustomerId).then(homeResponse => {
            if (this._isMounted)
                this.setState({ homeList: homeResponse.data, currentHome: homeResponse.data[0] }, async () => {
                    if (this.state.currentHome !== undefined && this.state.currentHome != null) {

                        await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(assetsResponse => {
                            if (this._isMounted)
                                this.setState({ hvacList: assetsResponse.data, currentHVACSystem: assetsResponse.data[0] }, async () => {
                                    // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                                    if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                        await AssetService.get(this.state.currentHVACSystem.assetId).then(hvacsResponse => {
                                            if (this._isMounted)
                                                this.setState({ assetList: hvacsResponse.data, currentAsset: hvacsResponse.data[0], loading: false }, async () => {
                                                    if (this.state.startTime != null) {
                                                        var elapsedTime = performance.now() - this.state.startTime;
                                                        Timing("Customer Data Loading", "loading", elapsedTime, "Customer Data loading from sidebar");
                                                        this.setState({ startTime: null });
                                                    };
                                                    this.setData(false, this.state.partnerList, this.state.myCurrentPartnerId/*props*/, this.state.myCurrentCustomerId/*props*/, this.state.homeList, this.state.hvacList, this.state.assetList, this.state.serviceTier);
                                                });
                                        });
                                    } else {
                                        this.setData(false, this.state.partnerList, this.state.myCurrentPartnerId/*props*/, this.state.myCurrentCustomerId/*props*/, this.state.homeList, this.state.hvacList, this.state.assetList, this.state.serviceTier);
                                        if (this.state.startTime != null) {
                                            var elapsedTime = performance.now() - this.state.startTime;
                                            Timing("Customer Data Loading", "loading", elapsedTime, "Customer Data loading from sidebar");
                                            this.setState({ startTime: null });
                                        };
                                    }
                                });
                        });
                    } else {
                        this.setData(false, this.state.partnerList, this.state.myCurrentPartnerId/*props*/, this.state.myCurrentCustomerId/*props*/, this.state.homeList, this.state.hvacList, this.state.assetList, this.state.serviceTier);
                        if (this.state.startTime != null) {
                            var elapsedTime = performance.now() - this.state.startTime;
                            Timing("Customer Data Loading", "loading", elapsedTime, "Customer Data loading from sidebar");
                            this.setState({ startTime: null });
                        };
                    }
                });
        }).catch(e => {
            console.log(e);
        });
    }

    setData(loading, partners, partnerId, customerId, homes, hvacs, assets, serviceTier) {
        this.setState({
            loading: loading,
            partnerList: partners,
            homeList: homes,
            hvacList: hvacs,
            assetList: assets,
            currentHVACSystem: hvacs[0],
            currentHome: homes[0],
            currentAsset: assets[0],
            serviceTier: serviceTier
        }, () => {
            localStorage.setItem('currentPartnerId', partnerId);
            localStorage.setItem('currentCustomerId', customerId);
                this.props.setPartnerList(partners);
                this.props.setServiceTier(serviceTier);
        });
    }

    render() {
        const { children } = this.props;

        if (this.state.partnerList && this.state.customerList/*props*/) {
            return (<>
                    <SideNav serviceTier={this.state.serviceTier} hasServiceTier={this.props.hasServiceTier} hasBilling={this.props.hasBilling} isActive={this.props.isActive} partners={this.state.partnerList} selectedPartner={this.props.currentPartnerId/*this.state.myCurrentPartnerId*//*props*/} customers={this.state.customerList/*props*/} selectedCustomer={this.props.currentCustomerId/*this.state.myCurrentCustomerId*//*props.c*/} customerName={this.state.customerName} onPartnerSelect={this.onPartnerSelect} onCustomerSelect={this.onCustomerSelect} onCustomerSearch={this.onCustomerSearch} setCustomerList={this.props.setCustomerList/*list => { this.setState({ customerList: list }); }*/} roles={this.props.roles} setActivePath={this.props.setActivePath} activePath={this.props.activePath} searchType={this.state.searchType} setSearchType={type => { this.setState({ searchType: type }); }} emailLoaded={this.state.emailLoaded/*props*/} addressLoaded={this.state.addressLoaded/*props*/} ></ SideNav>
                    {this.state.isChildMounted && children}
            </>
            );
        }
        else {
            return (null);
        }
    }
}

export default withDataContext(Sidebar);


